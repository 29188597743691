import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
//import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
//import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import * as Components from "src/components";
//import { fontWeight } from "@mui/system";
// import WebIcon from "@mui/icons-material/Web";
import LinkIcon from "@mui/icons-material/Link";
// import { FridayChallenge } from "src/utils/";
// import MailIcon from "@mui/icons-material/Mail";
// import BN from "src/components/bn";
import preise from "src/utils/preise";

const jahr = new Date().getFullYear();

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const card1 = (
  <>
    <CardContent>
      {/* <Typography
        sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
      >
        <span style={{ color: "red" }}>ACHTUNG HINWEIS für Samstag den 25.02.2023:</span>{" "}
        <p>
          Am Samstag (25.02.2023) finden bei uns die SNOOKER NDM SENIOREN statt. 
        </p>
        <p>
          Daher können wir für diesen Tag leider keinen weiteren Spielbetrieb anbieten. 
        </p>
        <span style={{ color: "red" }}>-----------------------------------------------</span>{" "}
      </Typography> */}
      <Typography sx={{ fontSize: 14, color: "black" }} gutterBottom>
        Aktuelle Infos für Gäste
      </Typography>
      <Typography variant="h5" component="div" color="black">
        Liebe{bull}Besucher{bull}des{bull}PBV{bull}Pinneberg,
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        unser Verein hat auch für Gäste geöffnet. Solange ein Vereinsmitglied
        vor Ort ist, könnt ihr uns besuchen kommen und Poolbillard oder Snooker
        spielen.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Bitte meldet euch vor dem Besuch unter 04101/5683301 an, damit ihr nicht
        umsonst kommt.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Eine Tagesmitgliedschaft kostet nur {preise.tagesmitgliedschaft},- Euro
        pro Person. Sie berechtigt zum Spielen bei Anwesenheit von mindestens
        einem Mitglied und für maximal diesen Tag.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Der Monatsbeitrag beträgt derzeit moderate {preise.basisMitgliedschaft}
        ,- EUR! Es lohnt sich also schon ab dem 4. Besuch im Monat, sich über
        eine Mitgliedschaft Gedanken zu machen! Sprecht uns gern darauf an!.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Wir freuen uns auf ein Wiedersehen – bis bald!
      </Typography>
      <Typography variant="h5" color="black">
        Euer{bull}Team{bull}des{bull}PBV{bull}Pinneberg
      </Typography>
    </CardContent>
  </>
);

const card2BN = (
  <>
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                marginTop: 3,
                marginBottom: 10,
              }}
            >
              <img
                src={require("src/files/bnNightNeu.png")}
                width="75%"
                height="auto"
                alt="Bild vom Flayer Billard Night"
              />
            </div>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom color="black">
            <span className="font-bold">
              BILLARD NIGHT - Ausschüttung: 100%
            </span>
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{ mt: 2, color: "black" }}
          >
            ... für SNOOKER und POOL
          </Typography>
          <Typography sx={{ mb: 1.5, color: "black" }}>
            An jeden letzten Freitag im Monat laden wir euch herzlich zur
            Billard Night in unser renoviertes Vereinsheim ein. Wir veranstalten
            gleichzeitig zwei Turniere, eines im Snooker und eines im
            Pool-Bereich. Die Teilnehmerzahl ist begrenzt auf 24 für Snooker und
            32 für Pool, um sicherzustellen, dass die Turniere sich nicht in die
            Länge ziehen. Frühzeitige Anmeldungen sind daher empfohlen, und wir
            folgen dem Prinzip "First Pay, First Play"!
          </Typography>
          <Typography sx={{ mb: 1.5, color: "black" }}>
            Für euer leibliches Wohl ist selbstverständlich gesorgt, wir haben
            köstliche Speisen für euch vorbereitet. Wir freuen uns auf einen
            entspannten Abend in bester Pool-Billard-Atmosphäre.
          </Typography>
          {/* <Typography sx={{ mb: 1.5, color: "black" }}>
            <span style={{ color: "red" }}>Meldeschluss (Online)</span> für die
            FridayChallenge ist{" "}
            <span style={{ color: "red" }}>immer am Mittwoch (23:59 Uhr)</span>{" "}
            vor dem Termin.
          </Typography> */}
          <Typography sx={{ mb: 1.5, color: "black" }}>
            Link für die Anmeldung und mehr Informationen sind auf unserer{" "}
            <NavLink to="/turniere" style={{ color: "red" }}>
              Turnierseite <LinkIcon />
            </NavLink>{" "}
            zu finden.
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </>
);

const cardDasTurnier = (
  <>
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                marginTop: 3,
                marginBottom: 10,
              }}
            >
              <img
                src={require("src/files/dasTurnier.jpg")}
                width="75%"
                height="auto"
                alt="Bild vom Flayer Das Turnier"
              />
            </div>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom color="black">
            <span className="font-bold">Das Turnier</span>
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{ mt: 2, color: "black" }}
          >
            ... für POOL, gespielt wird 9-Ball
          </Typography>
          <Typography sx={{ mb: 1.5, color: "black" }}>
            am 30.08.2024 ab 19:00 Uhr
          </Typography>
          <Typography sx={{ mb: 1.5, color: "black" }}>
            20,- EUR Startgeld, 100% werden ausgeschüttet
          </Typography>
          {/* <Typography sx={{ mb: 1.5, color: "black" }}>
            <span style={{ color: "red" }}>Meldeschluss (Online)</span> für die
            FridayChallenge ist{" "}
            <span style={{ color: "red" }}>immer am Mittwoch (23:59 Uhr)</span>{" "}
            vor dem Termin.
          </Typography> */}
          <Typography sx={{ mb: 1.5, color: "black" }}>
            Link für die Anmeldung und mehr Informationen sind auf unserer{" "}
            <NavLink to="/turniere" style={{ color: "red" }}>
              Turnierseite <LinkIcon />
            </NavLink>{" "}
            zu finden.
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </>
);

const card3 = (
  <>
    <CardContent>
      <Typography sx={{ fontSize: 24, color: "black" }} gutterBottom>
        Spielbetriebseinschränkungen
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Aufgrund von Ligabetrieb und Turnieren kommt es gelegentlich zu
        Spielbetriebseinschränkungen oder es können in seltenen Fällen auch
        stundenweise alle Tische belegt sein. Informationen dazu sind immer
        aktuell in unserem{" "}
        <NavLink to="/kalender" style={{ color: "red" }}>
          {" "}
          Kalender <LinkIcon />
        </NavLink>{" "}
        hinterlegt.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Um Details der Veranstaltungen einzusehen, klicke den Termin direkt an.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}></Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Grundsätzlich gilt:
        <ul>
          <li>
            - Bei Turnieren wie z.B. die "Billard Night" oder "Das Turnier" sind
            nahezu alle Tische belegt.
          </li>
          <li>
            - Bei Ligabetrieb stehen grundsätzlich ab 3 Mannschaften keine
            Pool-Tische zu Verfügung.
          </li>
        </ul>
      </Typography>
    </CardContent>
  </>
);

const card4 = (
  <>
    <CardContent>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span style={{ color: "red" }}>
          ACHTUNG HINWEIS:
          <br />
          Am Dienstag den 27.08.2024 bis einschließlich Donnerstag den
          29.08.2024 jeweils von 9:30 Uhr bis 17:30 Uhr und am Freitag den
          30.08.2024 von 9:30 Uhr bis 16:30 Uhr:
        </span>{" "}
        <p>
          In der genannten Zeitspanne findet bei uns ein NBV Jugend Kadertrainng
          statt.
        </p>
        <p>
          Daher ist kein Spielbetrieb im Altbau möglich, der Neubau kann wie
          gewohnt genutzt werden.
        </p>
      </Typography>
    </CardContent>
  </>
);

// const card5 = (
//   <>
//     <CardContent>
//       <Card variant="outlined" sx={{ marginBottom: 2 }}>
//         <img
//           src={require("src/files/friday_ich_bin_dabei.png")}
//           width="100%"
//           height="auto"
//           alt="Bild für die Friday Challenge"
//         />
//       </Card>
//       <Typography sx={{ mb: 1.5, color: "black" }}>
//         Die Serie {jahr} bittet unter der Regie von Borsti und Stefan{" "}
//         <span style={{ color: "red" }}>leider das letzte mal</span> an die
//         Platte!!!
//       </Typography>
//       <Typography sx={{ mb: 1.5, color: "black", fontSize: 20 }}>
//         Die Friday Challenge geht am {FridayChallenge.dateFridayChallenge} ab
//         10:00 Uhr als großes Endturnier ein letztes mal an Start!
//       </Typography>
//       <Typography sx={{ mb: 1.5, color: "red" }}>
//         Gespielt wird diesmal {FridayChallenge.ballToPlay}. Lasst Euch
//         überraschen! Alles kann passieren!
//       </Typography>

//       <Typography sx={{ mb: 1.5, color: "black" }}>
//         Anmeldung diesmal unter <MailIcon />{" "}
//         <a href={"mailto:fridaychallenge@pbv-pinneberg.de"}>
//           fridaychallenge@pbv-pinneberg.de
//         </a>{" "}
//         oder unter +49 1522 9444 952,{" "}
//         <span style={{ color: "red" }}>wichtig nur per WhatsApp oder SMS.</span>
//       </Typography>
//       <Typography sx={{ mb: 1.5, color: "black" }}>
//         Mehr Informationen sind auf unserer{" "}
//         <NavLink to="/turniere" style={{ color: "red" }}>
//           Turnierseite <LinkIcon />
//         </NavLink>{" "}
//         zu finden.
//       </Typography>
//       <Typography sx={{ mb: 1.5, color: "black", fontSize: 20 }}>
//         An dieser Stelle ein großes Dankeschön an Borsti und Stefan für ihr
//         langes Engagement.
//       </Typography>
//     </CardContent>
//   </>
// );

const card6 = (
  <>
    <CardContent>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        <h1>
          <NavLink to="/onlineRegistrierung">
            JETZT ONLINE-REGISTRIERUNG UND MITGLIEDER-SELBSTVERWALTUNG MÖGLICH{" "}
            <LinkIcon />
          </NavLink>{" "}
        </h1>
      </Typography>
    </CardContent>
  </>
);

const card7 = (
  <>
    <CardContent>
      <Typography variant="h5" sx={{ mb: 1.5, color: "black" }}>
        Norddeutsche {bull} Meisterschaften {bull} im {bull} Pool-Billard {bull}{" "}
        stehen {bull} vor {bull} der {bull} Tür
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Auch in diesem Jahr werden wieder in allen Disziplinen und Altersklassen
        die Norddeutschen Meister ermittelt. Die gesamte Veranstaltung wird vom
        18.05.2023 bis einschl. 21.05.2023 täglich im großzügig ausgestattetem
        Vereinsheim des PBV Pinnebergs stattfinden. Auch sehr viele unserer
        Spieler werden unter den 220 Spielern dieses Events mitmischen.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Begonnen wird am 18.05. mit 8 Ball und endet dann am Sonntag mit 9 Ball.
        Auch für interessierte Zuschauer wird es möglich sein den spannenden
        Partien beizuwohnen – jede/jeder ist herzlich eingeladen.
      </Typography>
    </CardContent>
  </>
);

const card8 = (
  <>
    <CardContent>
      <Typography sx={{ mb: 1, mt: 1, color: "red" }}>
        Tag der Offenen Tür am Sonntag, den 1. September
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        Am Sonntag, den ersten September beteiligt sich auch der 1. PBV
        Pinneberg am Tag des Sports.
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        Im Rahmen dieser Veranstaltung hat jeder Interessierte ab 14:00 Uhr die
        Möglichkeit, sich kostenlos an unseren 16 Pool- und 6 Snookertischen zu
        versuchen, auf Wunsch gerne auch unter Anleitung anwesender
        Vereinsmitglieder.
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "red", fontWeight: "bold" }}>
        Kommt vorbei!
      </Typography>
    </CardContent>
  </>
);

const card9 = (
  <>
    <CardContent>
      {/* <Typography sx={{ mb: 1, mt: 1, color: "red" }}>
        <span style={{ color: "red" }}>
          !!! NEU NEU NEU!!! <br />
        </span>{" "}
      </Typography> */}
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        Livescoring ist online!
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        <h1>
          <a
            href="http://billard-live.org/snooker/LiveScore_IN.php"
            target="_blank"
            rel="noopener"
          >
            ... hier geht's zum Scoreboard <LinkIcon />
          </a>
          {/* <NavLink to="/livescoring">
            ... hier geht's zum Scoreboard <LinkIcon />
          </NavLink>{" "} */}
        </h1>
      </Typography>
    </CardContent>
  </>
);

const card10 = (
  <>
    <CardContent>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        <span style={{ color: "red" }}>
          !!! ACHTUNG HINWEIS!!! <br />
        </span>{" "}
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        Einschränkung am 14.12.2023!
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        Am Donnerstag, den 14. Dezember 2023 von 9:00 bis ca. 14:00 haben wir in
        unseren Vereinsräumen eine Klasse der Leibnizschule Elmshorn zu Gast.
      </Typography>
      <Typography sx={{ mb: 1, mt: 1, color: "black" }}>
        Daher stehen in dieser Zeit beide Räume für den normalen Spielbetrieb
        nicht zur Verfügung.
      </Typography>
    </CardContent>
  </>
);

const Home: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            {/* <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card8}
            </Card> */}
            {/* <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card4}
            </Card> */}
            {/* <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card5}
            </Card> */}
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card6}
            </Card>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
            {/* <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {cardDasTurnier}
            </Card> */}
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card2BN}
            </Card>
            {/* <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card4}
            </Card> */}
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card3}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
